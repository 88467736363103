<template>
  <vuestic-widget :loading="fetching || loading" class="user-edit-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit User</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <div class="userEditForm">
      <ValidationObserver 
        v-slot="{ handleSubmit, invalid, dirty }"
        ref="userEditForm"
      >
        <form @submit.prevent="handleSubmit(onFormSubmit)">
          <div class="row">
            <div class="col-md-12">
              <text-input name="first_name" v-model="formData.first_name" label="First Name" validate="required" />
            </div>
            <div class="col-md-12">
              <text-input name="last_name" v-model="formData.last_name" label="Last Name" validate="required" />
            </div>
            <div class="col-md-6">
              <phone-number-input name="Phone" label="Phone" v-model="formData.phone" :required="true"/>
            </div>
            <div class="col-md-6">
              <text-input type="email" name="email" placeholder="Enter email" v-model="formData.email" label="Email" validate="required|email"/>
            </div>
          </div>

          <div class="row mt-1" v-if="formData && formData.role ==='AgencyUser'">
            <div class="col-md-12 mt-3 mb-4">
              <h5 class="text-primary font-weight-bold">User Type</h5>
            </div>
            <div class="col-md-12">
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="schedule" id="accountManager" value="1" v-model="formData.user_type">
                <label for="accountManager">
                  <span class="abc-label-text">Account Manager <a v-b-tooltip.hover title="Account Manager has access to Businesses, International, Plans, Zapier, Settings and Training pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="schedule" id="financialUser" value="2" v-model="formData.user_type">
                <label for="financialUser">
                  <span class="abc-label-text">Financial User <a v-b-tooltip.hover title="Financial User has access to Businesses, Plans, Zapier and Settings pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="schedule" id="marketingSupport" value="3" v-model="formData.user_type">
                <label for="marketingSupport">
                  <span class="abc-label-text">Marketing Support <a v-b-tooltip.hover title="Marketing Support has access to Businesses, Widget Landing and Zapier pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <router-link class="btn btn-primary btn-danger mr-2" :to="{name: 'agency.users.index'}">
                <span>Back</span>
              </router-link>
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="userPasswordForm mt-4">
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="passwordForm">
        <form @submit.prevent="handleSubmit(handleUpdatePassword)">
          <div class="row">
            <div class="col-md-6">
              <text-input name="NewPassword" v-model="newPassword.password" label="New Password"
                validate="required" ref="NewPassword" type="password"/>
            </div>
            <div class="col-md-6">
              <text-input name="ConfirmPassword" v-model="newPassword.password_confirmation" label="Confirm password"
                :validate="`required|password:${newPassword.password}`" type="password"/>
            </div>
          </div>
          <div class="mt-2 text-center">
            <button class="btn btn-primary" :disabled="processing || invalid">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
              <span v-else>Update</span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </vuestic-widget>
</template>

<script>
  import { mapState } from 'vuex';
  import TimeSelector from "../../forms/TimeSelector";

  export default {
    components: { 
      TimeSelector,
    },
    data() {
      return {
        loading: false,
        formData: {
          id: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          user_type: null,
          role: '',
        },
        newPassword: {
          password: null,
          password_confirmation: null,
        },
      };
    },

    created () {
    },

    computed: {
      ...mapState('agencyUser', {
        fetching: 'fetching',
        didFetch: 'didFetch',
        processing: 'processing',
      }),
    },
    mounted() {
      if (this.$route.params.id) {
        const id = this.$route.params.id
        this.loadData(id);
      } else {
        this.redirectOnError()
      }
    },
    methods: {
      redirectOnError() {
        this.$router.push({ name: 'agency.users.index' });
      },

      handleUpdatePassword() {
        const userId = this.$route.params.id;
        if (userId)
        {
          this.$store.dispatch('agencyUser/updatePassword', {id: userId, params:this.newPassword})
            .then(() => {
              this.newPassword = this.$options.data().newPassword
              this.$refs.passwordForm.reset()
            })
            .catch(() => {
            })
        }
      },

      loadData(id) {
        this.loading = true;
        this.$store
          .dispatch('agencyUser/get', id)
          .then((res) => {
            this.loading = false;
            this.initData(res.data);
          })
          .catch((err) => {
            this.loading = false;
            this.redirectOnError()
          })
      },

      initData(data) {
        this.formData = 
        {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          send_email: false,
          phone: data.phone,
          user_type: data.user_type,
          role: data.role,
        }
      },

      onFormSubmit() {
        const param = {
          id: this.formData.id,
          data: {
            ...this.formData,
          }
        }
        this.$store
          .dispatch('agencyUser/update', param)
          .then((data) => {
            this.initData(data)
          })
          .catch((err) => {
            let errors = err.response.data.errors
            if (errors)
            {
              this.$refs.userEditForm.setErrors(errors)
            }
          })
      }
    },

  };
</script>

<style lang="scss" scoped>
  .userEditForm,
  .userPasswordForm {
    max-width: 768px;
  }
  .selectedInstallWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    li {
      padding: 3px 10px;
      color: #ffffff;
      background-color: $rb-blue;
      border-radius: 10px;
      margin: 5px;
      font-weight: bold;
    }
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
</style>